<template>
    <div id="goods-add-box">
        <h3>表单信息</h3>
        <el-form ref="form" :model="wzOne" label-width="80px" style="width:500px;">
            <el-form-item label="文章标题">
                <el-input v-model="wzOne.title"></el-input>
            </el-form-item>

            <el-form-item label="文章类目">
                <el-select v-model="wzOne.kind">
                    <el-option lable="痔疮" value="痔疮"></el-option>
                    <el-option lable="肛瘘" value="肛瘘"></el-option>
                    <el-option lable="肛裂" value="肛裂"></el-option>
                    <el-option lable="脱肛" value="脱肛"></el-option>
                    <el-option lable="大便出血" value="大便出血"></el-option>
                    <el-option lable="肛周脓肿" value="肛周脓肿"></el-option>
                    <el-option lable="便秘" value="便秘"></el-option>
                    <el-option lable="胃肠镜检查" value="胃肠镜检查"></el-option>
                    <el-option lable="肛门疾病" value="肛门疾病"></el-option>
                    <el-option lable="肠道疾病" value="肠道疾病"></el-option>
                    <el-option lable="胃肠疾病" value="胃肠疾病"></el-option>
                    <el-option lable="疾病检查" value="疾病检查"></el-option>
                    <el-option lable="其他" value="其他"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="创建时间">
                <el-input v-model="wzOne.create_time"></el-input>
            </el-form-item>

            <el-form-item label="是否显示">
                <el-select v-model="wzOne.is_show">
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="2"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="图片链接">
                <el-col :span="18" style="margin-right:15px">
                    <el-input v-model="show_picture"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-upload class="upload-demo" action="https://shb.dreamsun.net/apis/upload/imgs" :on-success="fengmianSubmit" :file-list="fengMianList" list-type="picture">
                        <el-button size="small" type="primary">点击上传(不大于1mb)</el-button>
                    </el-upload>
                </el-col>
            </el-form-item>

            <div style="color: red; font-size: 12px; background-color: silver; padding: 5px 0;">
                <ul>
                    <li>文字内容请用 &lt;p&gt; 文字内容 &lt;/p&gt; 也可以在下方编辑框中选中后点击文字按钮</li>
                    <li>图片内容请用 &lt;img src=" 图片链接 " &gt; 也可以在下方编辑框中选中后点击图片按钮，输入图片链接</li>

                    <li>例如：&lt;p&gt; 白日依山尽，黄河入海流。欲穷千里目，更上一层楼。 &lt;/p&gt;</li>
                    <li>例如：&lt;img style="width:100%" src="https://static.dreamsun.net/static/img/2024-01-03/img-773aada6-cc6f-ac54-76ab-897e98ca277d.png" &gt;</li>
                </ul>
            </div>

            <div style="border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;background-color:#fbfbfb;">
                <el-button @click="change_wenzi()">文字</el-button>
                <el-button @click="change_bold()">加粗</el-button>
                <el-button @click="change_red()">红色</el-button>
                <el-button @click="change_img()">图片</el-button>
                <br>
                <hr style="border: 1px solid #DCDFE6;">
                <el-button @click="addbr()">换行</el-button>
                <el-button @click="change_view()" style="color:#409EFF;border-color:#409EFF">{{ viewStatus }}<i class="el-icon-edit"></i></el-button>
                <el-input id="contentBtn" @blur="selectStr()" v-show="showCode" :autosize="{ minRows: 30 }" type="textarea" v-model="wzOne.content" style="min-height:200px;"></el-input>
                <div v-show="showEdit" style="width: 98%; border:1px solid rgb(230, 230, 230); margin:5px 0; padding:5px;height:200px;">
                    <span style="width: 100%;" v-html="wzOne.content"></span>
                </div>
            </div>

            <!-- 
            <el-form-item label="病情描述">
                <div v-for="item, index in qus" :key=index>
                    <div>{{ item.question }}</div>
                    <div>{{ item.answer }}</div>
                </div>
            </el-form-item> -->

            <el-form-item>
                <el-button style="margin: 150px 0" type="primary" @click="onSubmit">确定</el-button>
                <el-button style="margin: 150px 50px">取消</el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import { getForm, postJson } from '../tools/tool'

export default {
    name: "WenZhangOne",
    data() {
        return {
            wzOne: null,
            showCode: true,
            showEdit: false,
            selectTxt: "",
            viewStatus: "目前是编辑状态",
            fengMianList: null,
            show_picture: [],
        }
    },
    created() {
        let wzId = this.$route.query.wz_id;
        console.log("wzId: ", wzId);
        if (wzId) {
            getForm('/wz/one', { id: wzId })
                .then(data => {
                    this.wzOne = data.data
                    console.log('wzOne:', data)
                }).catch(err => {
                    alert(err)
                })
        }
    },
    mounted() {
    },
    methods: {
        onSubmit() {
            // console.log(this.wzOne)
            // return
            this.wzOne.is_show = Number(this.wzOne.is_show)
            postJson("/wz/xg", this.wzOne).then(res => {
                alert(res.msg)
            })

        },
        change_wenzi() {
            this.wzOne.content = this.wzOne.content.replace(this.selectTxt, '<p>' + this.selectTxt + '</p>')
        },
        selectStr() {
            if (document.selection) {
                this.selectTxt = document.selection.createRange().text;
            } else {
                this.selectTxt = window.getSelection().toString();
            }
            console.log("selectTxt: ", this.selectTxt)
        },

        change_view() {
            if (this.showCode == true) {
                this.showCode = false
                this.showEdit = true
                this.viewStatus = "目前是展示效果"
            } else {
                this.showCode = true
                this.showEdit = false
                this.viewStatus = "目前是编辑状态"
            }
        },
        change_bold() {
            if (this.selectTxt != '') {
                this.wzOne.content = this.wzOne.content.replace(this.selectTxt, '<b>' + this.selectTxt + '</b>')
            }
        },
        change_red() {
            if (this.selectTxt != '') {
                this.wzOne.content = this.wzOne.content.replace(this.selectTxt, '<span style="color:red">' + this.selectTxt + '</span>')
            }

        },
        change_img() {
            this.$prompt('请填写图片地址', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if (value != '' && value != undefined) {
                    this.wzOne.content += '<img style="width:100%" src="' + value + '">'
                }

                this.$message({
                    type: 'success',
                    message: '插入图片成功: ' + value
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });

        },
        //换行按钮
        addbr() {
            this.wzOne.content += "<br />";
        },

        fengmianSubmit(res) {
            if (res.Code == "200") {
                this.show_picture = res.Data
                // this.fengMianList.push({ name: '封面.jpg', url: res.Data })
            }
            alert(res.Msg)
        },



    },
}
</script>

<style lang="less" scoped>
.upPicSty {
    width: 50px;
    height: 50px;
}
</style>